<template>
  <div class="grid grid-cols-2 gap-4 md:gap-10 overflow-hidden">
    <div v-for="(card, index) in cards" v-bind:key="card.key">
      <impact-card
        :card="card"
        :style="
          'animation-delay: ' + 200 * (index - 6 * Math.floor(index / 6)) + 'ms'
        "
      />
    </div>
  </div>
</template>

<script>
import ImpactCard from '@/components/impact/impact/Card'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
export default {
  components: { ImpactCard },

  setup: function () {
    const store = useStore()
    const i18n = useI18n({ useScope: 'global' })
    const { locale } = useI18n({ useScope: 'global' })

    const impact = computed(() => {
      return store.state.impact.impact
    })
    const cards = computed(() => {
      return [
        {
          key: 'clean_energy',
          title: i18n.t('impact.impact.clean_energy.title'),
          longTitle: i18n.t('impact.impact.clean_energy.long_title'),
          description: i18n.t('impact.impact.clean_energy.description'),
          learn_more: i18n.t('impact.impact.clean_energy.learn_more', {
            locale: locale.value
          }),
          value: impact?.value?.mwh_clean_energy ?? 0,
          rank: 21,
          suffix: 'MWh',
          totalRank: 5768,
          bg: 'blue-night',
          dark: true
        },

        {
          key: 'people',
          title: i18n.t('impact.impact.people.title'),
          longTitle: i18n.t('impact.impact.people.long_title'),
          description: i18n.t('impact.impact.people.description'),
          learn_more: i18n.t('impact.impact.people.learn_more', {
            locale: locale.value
          }),
          value: impact?.value?.people_impacted ?? 0,
          rank: 21,
          totalRank: 5768,
          bg: 'green-mint',
          dark: false
        },
        {
          key: 'jobs',
          title: i18n.t(
            'impact.impact.jobs.title',
            impact.value && impact.value.jobs_generated == 1 ? 1 : 0
          ),
          longTitle: i18n.t(
            'impact.impact.jobs.long_title',
            impact.value && impact.value.jobs_generated == 1 ? 1 : 0
          ),
          description: i18n.t('impact.impact.jobs.description'),
          learn_more: i18n.t('impact.impact.jobs.learn_more', {
            locale: locale.value
          }),
          value: impact?.value?.jobs_generated ?? 0,
          rank: 21,
          totalRank: 5768,
          bg: 'green-lime',
          dark: false
        },
        {
          key: 'sustainable_land',
          title: i18n.t('impact.impact.sustainable_land.title'),
          longTitle: i18n.t('impact.impact.sustainable_land.long_title'),
          description: i18n.t('impact.impact.sustainable_land.description'),
          learn_more: i18n.t('impact.impact.sustainable_land.learn_more', {
            locale: locale.value
          }),
          value: impact?.value?.ha_impacted ?? 0,
          suffix: 'ha',
          rank: 21,
          totalRank: 5768,
          bg: 'orange-3',
          dark: true,
          class: 'w-20 md:w-24'
        }
      ]
    })

    return { cards, impact }
  }
}
</script>

<style lang="css" scoped></style>
