<template>
  <div class="page flex">
    <div class="container lg:pt-20">
      <Back to="/" :title="$t('contracts.title')" />
      <ContractFilters />
      <TabsStatic
        labels="contracts.tabs"
        :labelsData="[totalOngoing, totalSettled]"
        :nTabs="2"
        v-model:current="currentTab"
      />
      <SortMenu
        class="my-6 relative z-40"
        :data="currentTab === 2 ? 'contracts.sort_settled' : 'contracts.sort'"
        :default="defaultSort"
        @sort="sort"
      />
      <div id="contracts">
        <infinite-scroll
            v-if="contracts && contracts.length && (!requesting || contracts.length)"
          @infinite-scroll="infiniteHandler"
          :noResult="has_next_page === null"
            :message="null"
          class="lg:grid grid-cols-2 gap-6"

        >
          <ContractCard
            v-for="(contract, index) in contracts"
            :key="contract.id"
            :contract="contract"
            class="mb-4"
            :style="
            'animation-delay: ' +
            100 * (index - 6 * Math.floor(index / 6)) +
            'ms'
            "
          />
        </infinite-scroll>
        <div v-else-if="contracts.length === 0 && !requesting" class="text-left">
          <p class="lg:text-lg font-bold text-grey-3 mb-1">
            {{ $t('contracts.no_contracts_title') }}
          </p>
          <p class="text-sm lg:text-base text-grey-3">
            {{ $t('contracts.no_contracts_text') }}
          </p>
        </div>
        <transition name="fade">
          <div v-if="requesting && !contracts.length" class="lg:grid grid-cols-2 gap-6">
            <ContractSkeleton
                v-for="index in 4"
                v-bind:key="'contract-skeleton-' + index"
                class="mb-4 md:mb-0"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/general/Back.vue'
import TabsStatic from '@/components/general/TabsStatic.vue'
import ContractFilters from '@/components/contracts/ContractFilters.vue'
import ContractCard from '@/components/contracts/ContractCard.vue'
import SortMenu from '@/components/general/SortMenu.vue'
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from 'vuex'
import ContractSkeleton from '@/components/contracts/ContractSkeleton'
import InfiniteScroll from 'infinite-loading-vue3'

export default {
  components: {
    ContractSkeleton,
    Back,
    TabsStatic,
    ContractCard,
    SortMenu,
    ContractFilters,
    InfiniteScroll
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.reset();
    });
  },
  setup() {
    const currentTab = ref(1)
    const store = useStore()
    const sortBy = ref(null)
    const sortCriteria = ref(null)

    const spaceAvail = ()=>{
      const div = document.getElementById('contracts');
      if (div) {
        const rect = div.getBoundingClientRect();
        return Math.floor(rect.bottom) <= Math.floor(window.innerHeight)
      }
      return false
    }

    const sortDict = ref({
      _next_payment: 'next_payment',
      end_recent_first: 'end_date',
      end_recent_last: '-end_date',
      outstanding_high: '-capital_outstanding',
      outstanding_low: 'capital_outstanding',
      first_settled: '-settled_at',
      last_settled: 'settled_at',
      paid_high: '-total_paid',
      paid_low: 'total_paid'
    })

    const search = computed(() => {
      return store.state.contracts.search
    })

    const requesting = computed(() => {
      return store.state.contracts.requesting
    })

    const error = computed(() => {
      return store.state.general.error
    })

    const contractFilters = computed(() => {
      return store.state.contracts.filters
    })

    const settledContractPages = computed(() => {
      return store.state.contracts.settled_contract_pages
    })

    const has_next_page = computed(() => {
      if (currentTab.value == 1)
        return store.state.contracts.active_contract_has_next_page
      else if (currentTab.value == 2)
        return store.state.contracts.settled_contract_has_next_page
      else return null
    })

    async function reset () {
      await store.dispatch('setContractFilters', null)
      await order()
    }

    onMounted(async () => {
      await store.dispatch('setSearch', null)
      if (!store.state.wallets.wallets) {
        await store.dispatch('fetchWallets')
      }
    })

    async function infiniteHandler() {
      if (
        has_next_page.value &&
        !error.value
      ) {
        await fecthContractsBasedOnTab(sortDict.value[sortCriteria.value ?? defaultSort.value])
      }
    }

    const fetchAllContracts = async () => {
      if (store.state.contracts.active_contract_has_next_page) {
        await store.dispatch('fetchContracts', { fetchState: "active" })
        fetchAllContracts()
      }
      if (store.state.contracts.settled_contract_has_next_page) {
        await store.dispatch('fetchContracts', { fetchState: "settled" })
        fetchAllContracts()
      }
    }

    const fecthContractsBasedOnTab = async (sort = null, reset = false) => {
      if (currentTab.value == 1)
        await store.dispatch('fetchContracts', { fetchState: "active", sort: sort, reset: reset })
      else if (currentTab.value == 2)
        await store.dispatch('fetchContracts', { fetchState: "settled", sort: sort, reset: reset })
    }

    const contracts = computed(() => {
      let temp = null
      if (currentTab.value == 1) {
        temp = store.getters.getContracts(sortBy.value, 'active')
      } else if (currentTab.value == 2) {
        temp = store.getters.getContracts(sortBy.value, 'settled')
      }
      if (temp)
        temp = temp.filter((c) => {
          return (
            c &&
            c.project_name
              .toLowerCase()
              .includes(search.value ? search.value.toLowerCase() : '')
          )
        })
      return temp
    })

    const totalOngoing = computed(() => {
      return store.state.contracts
        ? store.state.contracts.totalActiveContracts
        : 0
      // let cont = store.getters.getContracts(false, 'active')
      // return cont
      //   ? cont.filter((c)=> {
      //     return c.project_name.toLowerCase().includes(search.value ? search.value.toLowerCase() : '')
      //   }).length
      //   : 0
    })

    const totalSettled = computed(() => {
      return store.state.contracts
        ? store.state.contracts.totalSettledContracts
        : 0
      // let cont = store.getters.getContracts(true, 'settled')
      // return cont
      //   ? cont.filter((c)=> {
      //     return c.project_name.toLowerCase().includes(search.value ? search.value.toLowerCase() : '')
      //   }).length
      //   : 0
    })

    const sort = async (newSortCriteria) => {
      sortCriteria.value = newSortCriteria
      await order()
    }

    const order = async() =>{
      await fecthContractsBasedOnTab(sortDict.value[sortCriteria.value ?? defaultSort.value], true)
    }

    watch(currentTab, async () => {
      store.dispatch('setCurrentTab', currentTab.value)
      sort(defaultSort.value)
      // force scroll to fetch more contracts
      // scrollHeight does not reset so getInitialContracts() does not work
      window.scrollTo(0, window.innerHeight)
    })

    watch(contracts, async () =>{
      if (has_next_page.value && spaceAvail() && !error.value && contracts.value.length) {
        await fecthContractsBasedOnTab(sortDict.value[sortCriteria.value ?? defaultSort.value], false);
      } else if (settledContractPages.value.length === 0){
        await store.dispatch('fetchContracts', { fetchState: "settled" })
      }
    })

    watch(contractFilters, async () => {
      await order()
    })

    const defaultSort = computed(() => {
      return currentTab.value === 2 ? 'first_settled' : '_next_payment'
    })

    return {
      currentTab,
      contracts,
      totalOngoing,
      totalSettled,
      sort,
      has_next_page,
      infiniteHandler,
      requesting,
      defaultSort,
      reset
    }
  }
}
</script>