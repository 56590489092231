import { defineRule } from 'vee-validate'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
import store from '../store'
import i18n from '../i18n'

export default {
  install: () => {
    defineRule('required', (value) => {
      if (!value || !value.length) {
        return i18n.global.t('rules.required')
      }
      return true
    })
    defineRule('dollar', (value) => {
      // Field is empty, should pass
      if (!value || !value.length) {
        return true
      }
      // Check if email
      if (!/^(?!0+,00)(?=.{1,9}(\.|$))(?!0(?!,))\d{1,3}(,\d{3})*(.\d{2})?$/.test(value)) {
        return i18n.global.t('rules.dollar')
      }
      return true
    })

    defineRule('birthdate', (value) => {
      // Field is empty, should pass
      if (!value || !value.length) {
        return true
      }
      // Check if birthdate $"#%"!$!"%
      if (!/(^(0[1-9]|[12][0-9]|3[01]) \/ (0[1-9]|1[012]) \/ \d{4}$)/.test(value)) {
        return i18n.global.t('rules.dollar')
      }
      return true
    })

    defineRule('alphabet', (value) => {
      // Check if email
      if (!/^[A-zÀ-ú\s]+$/.test(value)) {
        return i18n.global.t('rules.alphabet')
      }
      return true
    })

    defineRule('address', (value) => {
      // Check if email
      if (!/^[[A-zÀ-ú0-9\s,.\-\\p{L}'/°º⁰]*$]*$/.test(value)) {
        return i18n.global.t('rules.alphabet')
      }
      return true
    })

    defineRule('zip', (value) => {
      // Check if email
      if (!/^[A-z0-9\s-]*$/.test(value)) {
        return i18n.global.t('rules.alphabet')
      }
      return true
    })

    defineRule('email', (value) => {
      // Field is empty, should pass
      if (!value || !value.length) {
        return true
      }
      // Check if email
      if (!/^[\w\-+\\.]+@([\w-]+\.)+[\w-]{2,12}$/.test(value)) {
        return i18n.global.t('rules.email')
      }
      return true
    })

    defineRule('vopay', (value) => {
      // Field is empty, should pass
      if (!value || !value.length) {
        return true
      }
      // Check if valid for vopay
      if (!/^[a-zA-Z0-9\s,.-]+$/.test(value)) {
        return i18n.global.t('rules.vopay')
      }
      return true
    })
    
    defineRule('website', (value) => {
      // Field is empty, should pass
      if (!value || !value.length) {
        return true
      }

      value = 'https://www.' + value

      // Check if website
      if (!/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(value)) {
        return i18n.global.t('rules.website')
      }
      return true
    })

    defineRule('maxLength', (value, args) => {
      // Check if email
      if (value?.length > args[0]) {
        return i18n.global.t('rules.max_length', {maxLength: args[0]}, args[0])
      }
      return true
    })    
    
    defineRule('exactLength', (value, args) => {
      // Check if email
      if (value.length != args[0]) {
        return i18n.global.t('rules.exact_length', {exactLength: args[0]}, args[0])
      }
      return true
    })

    defineRule('newPw', (value, args) => {
      if (value === args[0]) {
        return i18n.global.t('rules.password')
      }
      return true
    })

    defineRule('minLength', (value, args) => {
      if (value?.length < args[0]) {
        return i18n.global.t('rules.min_length', {minLength: args[0]}, args[0])
      }
      return true
    })

    defineRule('hasUpperLower', (value) => {
      if (!/(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
        return i18n.global.t('rules.cases')
      }
      return true
    })


    defineRule('hasNumberSymbol', (value) => {
      if (
        !/(?=.*\d)(?=.*[!\\"#$%&/()=?\\_\\-\\@\\^\\+\\'\\»\\«\\,\\.\\>\\<\\£\\§\\€\\`\\´\\~\\*])/.test(
          value
        )
      ) {
        return i18n.global.t('rules.number_symbol')
      }
      return true
    })

    defineRule('minValue', (value, args) => {
      value = value.toString()
      const { dollarFormatting } = useGlobalHelpers()
      if(value) {
        let val = value.replaceAll(',', '')
        let invalidValue = args[0]
        let isEuro = args[1] ?? 1

        if (parseInt(isEuro)) {
          val = parseFloat(val) * 100
          invalidValue = '$' + dollarFormatting(parseFloat(args[0]))
        }
  
        if (val < parseInt(args[0])) {
          return i18n.global.t('rules.min_value', {invalidValue}, invalidValue)
        }
  
        return true
      }
    })

    defineRule('maxValue', (value, args) => {
      const { dollarFormatting } = useGlobalHelpers()
      if (value) {
        let val = value.replaceAll(',', '')
        let invalidValue = args[0]
        let isEuro = args[1] ?? 1
  
        if (parseInt(isEuro)) {
          val = parseFloat(val) * 100
          invalidValue = '$' + dollarFormatting(parseFloat(args[0]))
        }
    
        if (val > parseInt(args[0])) {
          return i18n.global.t('rules.max_value', {invalidValue}, invalidValue)
        }
  
        return true
      }
    })

    defineRule('multiplier', (value, args) => {
      const { dollarFormatting } = useGlobalHelpers()

      if (value) {
        let val = value.replaceAll(',', '')
        let multiplier = args[0]
        let isEuro = args[1] ?? 1

        if (parseInt(isEuro)) {
          val = parseFloat(val) * 100
          multiplier = '$' + dollarFormatting(parseFloat(multiplier))
        }
    
        if (val % parseInt(args[0])) {
          return i18n.global.t('rules.multiplier', {multiplier}, multiplier)
        }
  
        return true
      }
    })
  }
}
